import {
    AviatorIcon,
    BalloonIcon, Bandidos,
    BigBassIcon, BookOfRa, Burning,
    CrownIcon, ElvisFrog,
    HellHotIcon,
    JokerIcon, PlinkIcon, SweetIcon, WolfIcon, ZeusIcon
} from "../RecommendedAppIcon/RecomendedAppIcon";

const AppListDevelopers = [
  {value: 'AviatorDev', label: 'Aviator Dev'},
  {value: 'BonanzaDev', label: 'Bonanza Dev'},
  {value: 'GoogleDev', label: 'Google Dev'},
  {value: 'PlayDev', label: 'Play Dev'},
  {value: 'PlinkoDev', label: 'Plinko Dev'},
  {value: 'ZeusDev', label: 'Zeus Dev'},
];
const AppListCategories = [
    {value: 'arcade', label: 'Arcade'},
    {value: 'casino', label: 'Casino'},
    {value: 'games', label: 'Games'},
    {value: 'sport', label: 'Sport'}
];
const AppListUserAge = [
    {value: '12', label: '+12'},
    {value: '16', label: '+16'},
    {value: '18', label: '+18'},
];
const AppListSize = [
    {value: '1', label: '1'},
    {value: '3', label: '3'},
    {value: '6', label: '6'},
    {value: '9', label: '9'},
    {value: '12', label: '12'},
    {value: '15', label: '15'},
];
const AppListInstall = [
    {value: '1000', label: '1000'},
    {value: '10000', label: '10000'},
    {value: '50000', label: '50000'},
    {value: '100000', label: '100000'},
];
const AppListButtonText = [
    {value: 'play', label: 'Play'},
    {value: 'install', label: 'Install'},
    {value: 'open', label: 'Open'}
];
const ListOptionLanguageReview = [
    {value: 'en', label: 'Англійська'},
    {value: 'ar', label: 'Арабська'},
    {value: 'bn', label: 'Бенгальська'},
    {value: 'bg', label: 'Болгарська'},
    {value: 'el', label: 'Грецька'},
    {value: 'da', label: 'Дацька'},
    {value: 'et', label: 'Естонська'},
    {value: 'id', label: 'Індонезійська'},
    {value: 'spa', label: 'Іспанська'},
    {value: 'it', label: 'Італійська'},
    {value: 'kz', label: 'Казахська'},
    {value: 'zh', label: 'Китайська'},
    {value: 'kor', label: 'Корейська'},
    {value: 'lav', label: 'Латвійська'},
    {value: 'lit', label: 'Литовська'},
    {value: 'nl', label: 'Нідерландська'},
    {value: 'de', label: 'Німецька'},
    {value: 'nor', label: 'Норвезька'},
    {value: 'pol', label: 'Польська'},
    {value: 'por', label: 'Португальська'},
    {value: 'rum', label: 'Руминська'},
    {value: 'rus', label: 'Свинособацька'},
    {value: 'slo', label: 'Словацька'},
    {value: 'slv', label: 'Словенська'},
    {value: 'tur', label: 'Турецька'},
    {value: 'hu', label: 'Угорська'},
    {value: 'ukr', label: 'Українська'},
    {value: 'fi', label: 'Фінська'},
    {value: 'fr', label: 'Французька'},
    {value: 'hin', label: 'Хінді'},
    {value: 'cs', label: 'Чеська'},
    {value: 'swe', label: 'Швецька'},
    {value: 'jpn', label: 'Японська'},
    {value: 'uz', label: 'Узбекська'},
    {value: 'vi', label: 'Вєтнамська'},
    {value: 'my', label: 'Бірманська'},
    {value: 'ms', label: 'Малайська'},
    {value: 'hr', label: 'Хорватська'},
];

const ListOptionLanguageEnVersion = [
    {value: 'ar', label: 'Arabic'},
    {value: 'bn', label: 'Bengali'},
    {value: 'bg', label: 'Bulgarian'},
    {value: 'my', label: 'Burmese'},
    {value: 'zh', label: 'Chinese'},
    {value: 'cs', label: 'Czech'},
    {value: 'da', label: 'Danish'},
    {value: 'nl', label: 'Dutch'},
    {value: 'en', label: 'English'},
    {value: 'et', label: 'Estonian'},
    {value: 'fi', label: 'Finnish'},
    {value: 'fr', label: 'French'},
    {value: 'de', label: 'German'},
    {value: 'el', label: 'Greek'},
    {value: 'hin', label: 'Hindi'},
    {value: 'hu', label: 'Hungarian'},
    {value: 'id', label: 'Indonesian'},
    {value: 'it', label: 'Italian'},
    {value: 'jpn', label: 'Japanese'},
    {value: 'kor', label: 'Korean'},
    {value: 'kz', label: 'Kazakh'},
    {value: 'lav', label: 'Latvian'},
    {value: 'lit', label: 'Lithuanian'},
    {value: 'nor', label: 'Norwegian'},
    {value: 'pol', label: 'Polish'},
    {value: 'por', label: 'Portuguese'},
    {value: 'rum', label: 'Romanian'},
    {value: 'rus', label: 'Russian'},
    {value: 'slo', label: 'Slovak'},
    {value: 'slv', label: 'Slovenian'},
    {value: 'spa', label: 'Spanish'},
    {value: 'swe', label: 'Swedish'},
    {value: 'tur', label: 'Turkish'},
    {value: 'ukr', label: 'Ukrainian'},
    {value: 'uz', label: 'Uzbek'},
    {value: 'vi', label: 'Vietnamese'},
    {value: 'az', label: 'Azerbaijani'},
    {value: 'ms', label: 'Malay'},
    {value: 'hr', label: 'Croatian'},
]

const LanguagePwaList = [
    {value: 'en', label: 'Англійська'},
    {value: 'ar', label: 'Арабська'},
    {value: 'bn', label: 'Бенгальська'},
    {value: 'bg', label: 'Болгарська'},
    {value: 'el', label: 'Грецька'},
    {value: 'da', label: 'Дацька'},
    {value: 'et', label: 'Естонська'},
    {value: 'id', label: 'Індонезійська'},
    {value: 'spa', label: 'Іспанська'},
    {value: 'it', label: 'Італійська'},
    {value: 'kz', label: 'Казахська'},
    {value: 'zh', label: 'Китайська'},
    {value: 'kor', label: 'Корейська'},
    {value: 'lav', label: 'Латвійська'},
    {value: 'lit', label: 'Литовська'},
    {value: 'nl', label: 'Нідерландська'},
    {value: 'de', label: 'Німецька'},
    {value: 'nor', label: 'Норвезька'},
    {value: 'pol', label: 'Польська'},
    {value: 'por', label: 'Португальська'},
    {value: 'rum', label: 'Руминська'},
    {value: 'rus', label: 'Свинособацька'},
    {value: 'slo', label: 'Словацька'},
    {value: 'slv', label: 'Словенська'},
    {value: 'tur', label: 'Турецька'},
    {value: 'hu', label: 'Угорська'},
    {value: 'ukr', label: 'Українська'},
    {value: 'fi', label: 'Фінська'},
    {value: 'fr', label: 'Французька'},
    {value: 'hin', label: 'Хінді'},
    {value: 'cs', label: 'Чеська'},
    {value: 'swe', label: 'Швецька'},
    {value: 'jpn', label: 'Японська'},
    {value: 'uz', label: 'Узбекська'},
    {value: 'vi', label: 'Вєтнамська'},
    {value: 'my', label: 'Бірманська'},
    {value: 'az', label: 'Азербайджанська'},
    {value: 'ms', label: 'Малайська'},
    {value: 'hr', label: 'Хорватська'},
];

const CountReviews = [
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4', label: '4'},
    {value: '5', label: '5'},
    {value: '6', label: '6'},
    {value: '7', label: '7'},
    {value: '8', label: '8'},
    {value: '9', label: '9'},
    {value: '10', label: '10'},
];

const AppCategories = [
  {value: "arcade", label: "Аркада"},
  {value: "puzzle", label: "Головоломки"},
  {value: "racing", label: "Гонки"},
  {value: "trivia", label: "Дрібниці"},
    {value: "action", label: "Екшн"},
    {value: "casino", label: "Казино"},
    {value: "casual", label: "Казуальні"},
    {value: "card", label: "Карткові"},
    {value: "music", label: "Музика"},
    {value: "board", label: "Настільні"},
    {value: "educational", label: "Освітні"},
    {value: "adventure", label: "Пригоди"},
    {value: "role-playing", label: "Рольові ігри"},
    {value: "simulation", label: "Симулятори"},
    {value: "word", label: "Слова"},
    {value: "sports", label: "Спорт"},
    {value: "strategy", label: "Стратегія"}
];

const AppCategoriesEnVersion = [
    {value: "action", label: "Action"},
    {value: "adventure", label: "Adventure"},
    {value: "arcade", label: "Arcade"},
    {value: "board", label: "Board"},
    {value: "card", label: "Card"},
    {value: "casino", label: "Casino"},
    {value: "casual", label: "Casual"},
    {value: "educational", label: "Educational"},
    {value: "music", label: "Music"},
    {value: "puzzle", label: "Puzzle"},
    {value: "racing", label: "Racing"},
    {value: "role-playing", label: "Role-playing"},
    {value: "simulation", label: "Simulation"},
    {value: "sports", label: "Sports"},
    {value: "strategy", label: "Strategy"},
    {value: "trivia", label: "Trivia"},
    {value: "word", label: "Word"}
];

const AppRecommendedIcon = [
    {value: 'aviator', label: 'Aviator', icon: AviatorIcon},
    {value: 'balloon', label: 'Balloon', icon: BalloonIcon},
    {value: 'bandidos', label: 'Bandidos', icon: Bandidos},
    {value: 'big_bass', label: 'Big Bass', icon: BigBassIcon},
    {value: 'book_of_ra', label: 'Book of Ra', icon: BookOfRa},
    {value: 'burning', label: 'Burning Helli', icon: Burning},
    {value: 'crown', label: 'Crown', icon: CrownIcon},
    {value: 'elvis_frog', label: 'Elvis Frog', icon: ElvisFrog},
    {value: 'hell_hot', label: 'Hell Hot', icon: HellHotIcon},
    {value: 'joker', label: 'Joker', icon: JokerIcon},
    {value: 'plinko', label: 'Plink', icon: PlinkIcon},
    {value: 'sweet', label: 'Sweet Bonanza', icon: SweetIcon},
    {value: 'wolf', label: 'Wolf', icon: WolfIcon},
    {value: 'zeus', label:'Zeus', icon: ZeusIcon}
];

const TemplateAppScreen = [
    {value: 'aviator', label: 'Aviator'},
    {value: 'balloon', label: 'Balloon'},
    {value: 'bonanza_world', label: 'Bonanza World'},
    {value: 'dating', label: 'Dating'},
    {value: 'fish_bonanza', label: 'Bonanza Fish'},
    {value: 'egypt', label: 'Egypt'},
    {value: 'plinko', label: 'Plinko'},
    {value: 'tiger', label: 'Tiger'},
    {value: 'zeus', label: 'Zeus'},
    {value: 'sport', label: 'Sport'},
    {value: 'dragons', label: 'Dragons'},
    {value: 'killroad', label: 'Kill Road'},
    {value: 'machien', label: 'Machineg Gun'},
    {value: 'majestic', label: 'Majestic Claws'},
    {value: 'devils', label: 'Devil\'s Charm'},
    {value: 'lavacoin', label: 'Lava Coins'},
    {value: 'wild_buffalo', label: 'Wild Buffalo'},
];

export {
    AppListDevelopers,
    AppListCategories,
    AppListUserAge,
    AppListSize,
    AppListInstall,
    AppListButtonText,
    ListOptionLanguageReview,
    LanguagePwaList,
    CountReviews,
    AppCategories,
    AppRecommendedIcon,
    ListOptionLanguageEnVersion,
    AppCategoriesEnVersion,
    TemplateAppScreen
}